import React from "react"
import { debounce } from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import Layout from "../components/Layout"
import LayoutContainer from "../components/LayoutContainer"
import { SHOWCASE_ROUTE } from "../constants"
import DarkBackgroundSVG from "../components/DarkBackgroundSVG"
import DisplayDialog, { ActiveItem } from "../components/DisplayDialog"

const initialWidth = 0;
export default () => {
  const [windowWidth, setWindowWidth] = React.useState(initialWidth);
  const [activeItem, setActiveItem] = React.useState<ActiveItem>(null)
  React.useEffect(() => {
    setWindowWidth(window.innerWidth);
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowWidth(window.innerWidth)
    }, 500)
    window.addEventListener("resize", debouncedHandleResize)
    return () => window.removeEventListener("resize", debouncedHandleResize)
  }, [])
  let gridColumnCount = 3
  if (windowWidth < 1024 && windowWidth >= 768) {
    gridColumnCount = 2
  } else if (windowWidth < 768) {
    gridColumnCount = 1
  }
  const pageData = useStaticQuery(graphql`
    query {
      allShowcase(
        sort: { order: [DESC, DESC], fields: [sortRank, createdAt] }
        filter: { name: { ne: "DUMMY_DO_NOT_DELETE" } }
      ) {
        edges {
          node {
            name
            description
            showcaseImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const showcaseArray = pageData.allShowcase.edges
    .map((item: any) => item.node)
    .reduce(
      (a: any, b: any, index: number) => {
        const arrIndex = index % gridColumnCount
        a[arrIndex].push(b)
        return a
      },
      Array.from({ length: gridColumnCount }).map(() => [])
    )
  return (
    <Layout
      pageTitle="Showcase"
      pageURL={SHOWCASE_ROUTE}
      pageDescription="Check out what QC Cart Craft has built. Our custom carts can be found all over the Queen City and beyond."
    >
      <header className="text-white relative py-12 bg-teal-800">
        <DarkBackgroundSVG />
        <LayoutContainer>
          <div className="lg:max-w-screen-lg m-auto max-w-screen-md relative z-10 text-center">
            <h6 className="tracking-widest text-teal-200 text-sm mb-3">
              SHOWCASE
            </h6>
            <h1 className="text-5xl sm:text-6xl font-bold leading-none mb-4">
              Check Out What We've Built
            </h1>
            <p className="text-white text-sm">
              Our custom carts can be found all over the Queen City and beyond.
            </p>
          </div>
        </LayoutContainer>
      </header>
      <section className="py-8">
        {windowWidth > initialWidth ? (<LayoutContainer> {/* Loading this after client loads to avoid images jumping around */}
          <div className="flex flex-wrap -mx-2">
            {showcaseArray.map((col: any[], i: number) => {
              return (
                <div
                  key={`col-${i}`}
                  className="w-full md:w-6/12 lg:w-4/12 px-2"
                >
                  {col.map((item, j) => {
                    if (!item.showcaseImage) {
                      return null
                    }
                    function handleClick() {
                      setActiveItem({
                        fluidObject: item.showcaseImage.childImageSharp.fluid,
                        name: item.name,
                        description: item.description,
                      })
                    }
                    return (
                      <div
                        className="w-full shadow-xl mb-4 cursor-pointer"
                        role="button"
                        tabIndex={-1}
                        key={`img-${i}-${j}`}
                        onClick={handleClick}
                      >
                        <GatsbyImage
                          fluid={item.showcaseImage.childImageSharp.fluid}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </LayoutContainer>) : (
          <LayoutContainer>
            <div className="flex flex-wrap -mx-2">
              {Array.from({length: 3}).map((item, index) => {
                return (
                  <div className="w-full md:w-6/12 lg:w-4/12 px-2" key={`placeholder-${index}`}>
                    <div className="w-full shadow-xl mb-4 image-placeholder" />
                  </div>
                )
              })}
              </div>
          </LayoutContainer>
        )}
      </section>
      <DisplayDialog activeItem={activeItem} setActiveItem={setActiveItem} />
    </Layout>
  )
}
