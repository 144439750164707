import React from "react"
import Dialog from "@material-ui/core/Dialog"
import GatsbyImage, { FluidObject } from "gatsby-image"
import { Link } from "gatsby"
import { CONTACT_US_ROUTE, CONTACT_FORM_ID } from "../constants"
import { convertCentsToCurrency } from "../utils"

export type ActiveItem = {
  fluidObject: FluidObject
  name: string
  description: string
  initialContactMessage?: string
  price?: number
} | null
interface DisplayDialogProps {
  activeItem: ActiveItem
  setActiveItem: (item: ActiveItem) => void
}
const DisplayDialog: React.FC<DisplayDialogProps> = ({
  activeItem,
  setActiveItem,
}) => {
  const [open, setOpen] = React.useState(false)
  function handleClose() {
    setOpen(false)
  }
  function handleExited() {
    setActiveItem(null)
  }
  React.useEffect(() => {
    if (activeItem) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [activeItem])
  if (!activeItem) {
    return null
  }
  const {
    fluidObject,
    name,
    description,
    initialContactMessage,
    price,
  } = activeItem
  console.log(price)
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onExited={handleExited}
      maxWidth="sm"
      fullWidth
    >
      <div className="w-full">
        <div className="w-full">
          <GatsbyImage fluid={fluidObject} />
        </div>
        <div className="bg-gray-900 text-white p-2">
          <h5 className="font-bold text-lg">{name}</h5>
          {description && <p className="mt-2">{description}</p>}
          {(price || price === 0) && (
            <h5 className="mt-2 font-bold text-xl">
              ${convertCentsToCurrency(price)}
            </h5>
          )}
          {initialContactMessage && (
            <div className="py-1 w-full">
              <Link
                className="block text-center shadow-sm bg-teal-600 hover:bg-teal-500 focus:shadow-outline focus:outline-none text-white py-2 rounded"
                to={`${CONTACT_US_ROUTE}#${CONTACT_FORM_ID}`}
                state={{ initialContactMessage }}
              >
                REQUEST MORE INFO
              </Link>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default DisplayDialog
